$primary: #FFF6F3;
$white: #FFFFFF;
$black: #000000;
$hover: #282828;
$lightgray: #9fa1a4;
$gainsboro: #ededed;
$pink: #E79A94;
$lightpink: #FFB5AE;
$dark: #515151;

$link-color: $dark;

$page-max-width: inherit;
$page-text-size: 1.15rem !important;
$page-deck-size: 1.25rem !important;
$page-text-size: 1rem !important;

$theme-colors: (
    "primary": $primary,
);

:root {
    --primary-color: #FFF6F3;
}

.btn-primary {
    background-color: $pink !important;
    color: $white !important;
    border: 1px solid $pink !important;
    &:hover {
        background-color: $lightpink !important;
    }
}

@font-face {
    font-family: "Akkurat Regular";
    src: url("https://cdn.tag-walk.com/showroom/linendreamlab/Akkurat+Pro+Family/AkkRg_Pro_1.otf") format("opentype");
}

@font-face {
    font-family: "Akkurat Bold";
    src: url("https://cdn.tag-walk.com/showroom/linendreamlab/Akkurat+Pro+Family/AkkBd_Pro_1.otf") format("opentype");
}

@font-face {
    font-family: "Akkurat Light";
    src: url("https://cdn.tag-walk.com/showroom/linendreamlab/Akkurat+Pro+Family/AkkLg_Pro_1.otf") format("opentype");
}

@font-face {
    font-family: "Self Modern";
    src: url("https://cdn.tag-walk.com/showroom/linendreamlab/Self+Modern/Self+Modern.otf") format("opentype");
}

$font-family-base: "Akkurat Regular" !important;
$font-family-bold: "Akkurat Bold" !important;
$font-family-text: "Self Modern" !important;
$font-family-light: "Akkurat Light" !important;

@import "../../common/scss/_mixins.scss";
@import "~bootstrap";

body {
    background-color: var(--primary-color);
    &.fixed-menu {
        #header,
        .navbar-menu {
            position: sticky;
        }
        #header {
            z-index: 50;
            top: 0;
        }
        .navbar-menu {
            top: var(--header-height);
            z-index: 40;
        }
        @include media-breakpoint-between(sm, lg) {
            #navbar-collapsed {
                position: fixed;
                width: 100%;
                z-index: 100;
                background-color: var(--primary-color);
            }
        }
    }
}

header {
    background-color: var(--primary-color) !important;
    a:link, a:visited {
        color: $dark !important;
    }
    .website-locale-selector {
        background-color: var(--primary-color);
        .current-locale-menu {
            background-color: var(--primary-color) !important;
            color: $dark;
        }
    }
    #banner {
        height: 100%;
        .website-logo {
            @include media-breakpoint-down(xs) {
                width: 82% !important;
            }
        }
    }

    .burger-menu {
        background-color: var(--primary-color);

        .close-menu {
            padding-top: .3rem !important;
        }
    }

    .claim {
        color: $dark;

        h2 {
            font-size: .9rem !important;
        }

        @include media-breakpoint-down(md) {
            width: 75%;
            margin: 0 auto;
        }
    }

    #left-header {
        .burger-icon svg,
        .close-menu svg  {
            fill: $dark;
        }
        .burger-icon::after {
            content: 'MENU';
            position: relative;
            color: $dark;
        }
    }
    &.bottom-border {
        border-bottom: 2px solid $lightgray;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(81, 81, 81, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
}

body #navbar-collapsed {
    .navbar-collapsed-inner li a  {
        color: $dark;
    }
}

footer {
    margin: 0;
    padding: 1.5rem;
    a:link,
    a:visited {
        color: $dark;
    }
    hr {
        border-color: $lightgray;
    }
}

.list-designers-container {
    #results {
        background-color: var(--primary-color);
    }
}

main[data-current-route="page_show"] {
    .filters-container  {
        padding: .75rem 3rem 0 .75rem;
        margin: 0;
    }
}

body .navbar-menu {
    border-bottom: 2px solid $lightgray;
    background-color: var(--primary-color);
    ul.navbar-menu-inner {
        padding-bottom: .25rem !important;
        li:after {
            display: none;
        }
        a {
            &:link,
            &:visited {
                color: $black;
                &:hover {
                    color: lighten($black, 30%);
                    transition: .3s;
                }
            }
            font-size: 1.5rem !important;
            margin: 0 .5rem;
            font-family: $font-family-text;
            font-weight: bold;
            &.selected {
                border-bottom: 4px solid;
            }
        }
        @include media-breakpoint-down(md) {
            display: block !important;
        }
    }
}

main[data-current-route="homepage"] {
    padding: 0 !important;
    .filters-container  {
        padding: .75rem 3rem;
        margin: 0;
    }
    .list-designers-container {
        #results {
            padding: 0 !important;
        }
    }
}

body.error-page #content .alert-container {
    background-color: transparent;
    color: $dark;

    h1 {
        color: $dark;
        font-family: $font-family-text;
    }
}

body.login {
    min-height: 100vh;
    header,
    nav {
        display: none !important;
    }
    main {
        padding: 0 !important;
        margin-bottom: 0;
    }
    #content {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
    }
    .left-container {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #FFF6F3;
        img {
            width: 80%;
        }
        footer {
            position: absolute;
            bottom: 2rem;
        }
    }
    .form-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50%;
        background-color: #f8ccc9;
        .register,
        .btn-submit {
            padding-bottom: .5rem;
            padding-top: .5rem;
            background-color: #FFF6F3;
            color: $dark !important;
            &:hover {
                background-color: darken(#FFF6F3, 5%) !important;
                border-color: $white;
            }
        }
        .homepage-link {
            color: $dark;
            text-transform: uppercase;
            position: absolute;
            bottom: 2rem;
            margin: 1.5rem;
            font-size: .7rem;
            &:hover {
                color: $black;
                transition: .2s;
            }
        }
    }
    @include media-breakpoint-down(md) {
        #content {
            .form-login,
            .left-container {
                width: 100%;
                position: relative;
            }
            .form-login {
                order: -1;
            }
        }
    }
}

.form-register {
    #submit {
        background-color: $pink !important;
        color: $white !important;
        border: 1px solid $pink !important;
        &:hover {
            background-color: $lightpink !important;
        }
    }
    input[type="checkbox"] {
        background-color: $white;
    }
}

#photo-search {
    .photo-list-item {
        background-color: var(--primary-color);
    }
    .photo-list-item-link-details {
        background-color: var(--primary-color);
        margin-bottom: 0;
        padding-bottom: 2rem !important;
        p {
            text-align: center;
            color: $black;
            font-size: .6rem;
        }
    }
    .img-container {
        position: relative;
    }

    #viewer {
        .viewer-content {
            background-color: var(--primary-color);
        }

        .carousel-clearfix {
            ::-webkit-scrollbar-track-piece {
                border-color: var(--primary-color);
            }

            ::-webkit-scrollbar-thumb {
                border-color: var(--primary-color);
            }
        }

        .carousel-media-description-title {
            display: none !important;
        }

        .carousel-media-descritpion-body {
            margin-top: 4rem;
        }

        .certifications {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 1rem;
            max-width: 100%;

            img {
                margin-top: 1rem;
                padding: 1rem;
                background-color: white;
                max-height: 120px;
            }

            a:not(:first-of-type) {
                margin-left: 1rem;
            }
        }

        .requests {
            position: absolute;
            top: 0;
            right: calc(1.5rem + 20px);
            .btn-add-request {
                background-color: var(--primary-color);
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    font-size: 1.2rem;
                    margin: .25rem;
                }
                &:hover {
                    color: $black;
                }
            }
        }
        .bottom-clearfix {
            display: flex;
            justify-content: space-between;
            padding-top: 2rem;
            text-transform: uppercase;
            a {
                text-decoration: underline;
                position: relative;
                margin: 0 .5rem;
                &::after {
                    content: '?';
                    position: absolute;
                    right: calc(100% + .2rem);
                    padding: 0 .25rem;
                    border-radius: 50%;
                    border: 1px solid $lightgray;
                    color: $lightgray;
                    font-size: .6rem;
                }
            }
            span {
                margin: 0 .5rem;
            }
        }
        .carousel-details-collection {
            @include horizontal-scrollbar(var(--primary-color), $gainsboro);
        }
        .carousel-collection-item {
            max-width: unset !important;
            padding: 0 .5rem !important;
            img {
                max-height: 150px;
                width: auto !important;
            }
        }
        .carousel-details-footer .row-no-gutters {
            display: none;
        }
        .carousel-collection-list {
            overflow-x: auto;
        }
        .carousel-collection-text {
            display: none;
        }
    }

    #add-form-container {
        border: none !important;
    }
    @include media-breakpoint-down(md) {
        #viewer {
            .requests {
                left: calc(50% + 1rem);
                width: calc(100% - 2rem);
                text-align: center;
                transform: translate(-50%);
            }
            .details {
                margin-top: 4rem;
            }
            .carousel-media-descritpion-body {
                margin-top: 0;
            }
        }
    }
}

main[data-current-route="page_show"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page_presentation {
    @include media-breakpoint-down(lg) {
        min-height: 300px;
    }

    @include media-breakpoint-up(lg) {
        background-attachment: fixed;
        min-height: 400px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 500px;
    }

    background-position: center 25%;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0;

    @media (max-width: 480px) {
        min-height: 180px;
        max-height: 250px;
    }

    h1 {
        @media (max-width: 700px) {
            font-size: 2rem !important;
        }

        font-size: 3rem !important;
        font-family: $font-family-text;
        font-weight: 700;
        color: $white;
        text-align: center !important;
        text-shadow: 0 0 8px #222200;
    }

    display: flex;
    justify-content: center;
    align-items: center;
}

.viewer .modal-container .viewer-content .viewer-body {
    &.embed {
        .carousel-photos {
            #carousel {
                height: 100%;

                .carousel-inner {
                    height: 100%;

                    .carousel-item {
                        height: 100%;

                        .embed-responsive {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

.page.container {
    background-color: var(--primary-color);
    padding: 2rem;

    @media (min-width: 1350px) {
        max-width: 90% !important;
    }

    table {
        display: none;
    }

    h2 {
        font-family: $font-family-bold;
        font-size: 1.45rem !important;
        color: $dark;
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center !important;
        margin: 0 auto !important
    }

    h3 {
        font-family: $font-family-bold;
        font-size: 1.25rem !important;
        color: $dark;
        text-align: center !important;
    }

    p {
        font-family: $font-family-base;
        font-size: $page-text-size;
        color: $dark;
        padding-bottom: 3rem;
        text-align: center;
        max-width: $page-max-width;
        margin: 0 auto;
        line-height: 2rem;
    }

    ul {
        font-family: $font-family-base;
        font-size: $page-text-size;
        color: $dark;
        text-align: center;
        max-width: $page-max-width;
        margin: 0 auto;
        line-height: 2.5rem;
        list-style-type: none;
    }

    img {
        object-fit: contain;
        max-width: 90%;
        max-height: 500px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    a {
        color: $link-color;
    }

    hr {
        border-top: 2px solid $dark;
        margin-bottom: 1rem;
    }

    h2 + .row,
    h3 + .row,
    h3 + p,
    img + p,
    figure + p,
    hr + img,
    hr + h3 {
        margin-top: 2rem !important;
    }

    h3 + img {
        margin-top: 2rem !important;
        max-height: 800px !important;
    }

    p + p {
        padding-bottom: 2rem !important;
    }

    .row {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .col {
        font-family: $font-family-base;
        font-size: $page-text-size;
        color: $dark;
        padding-bottom: 3rem;
        text-align: center;
        margin: 0 auto;
        line-height: 2rem;
        padding-left: 15px;
        padding-right: 15px;

        p {
            padding-bottom: 1rem !important;
        }

        h3 {
            padding-bottom: 1rem !important;
        }

        // should only affect the european-industry page
        h3 + img {
            height: 300px;
            margin-top: 0 !important;
        }
    }

    .mockup-img {
        width: 90%;
        height: 300px;
        background-color: #EDCEC3;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;

        text-align: center;
        font-size: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    i {
        font-size: 0.85rem !important;
    }

    &[data-slug="about-celc"] {
        .page_presentation {
            background-image: url("https://cdn.tag-walk.com/showroom/linendreamlab/homepage/section-about-celc.jpg");
        }

        h2 {
            font-size: 1.5rem !important;
            font-family: $font-family-text;
        }

        & > .about-celc-step-container:first-of-type {
            .col:first-of-type {
                padding-bottom: 0 !important;
            }

            h2 {
                padding-bottom: 2rem !important;
            }

            p {
                font-size: 1.25rem !important;
            }
        }

        .about-celc-step-container:not(:first-of-type) {
            max-width: 1100px;
        }
    }

    &[data-slug="services"] {
        .page_presentation {
            background-image: url("https://cdn.tag-walk.com/showroom/linendreamlab/homepage/section-services.jpg");
        }

        & > p:first-of-type {
            font-size: 1.25rem !important;
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
        }

        hr {
            margin-top: 2rem;
            margin-bottom: 0;
        }

        h2 {
            font-family: $font-family-text;
            text-align: center !important;
            max-width: inherit !important;
            font-size: 1.75rem !important;

            & + p {
                padding-bottom: 0 !important;
            }
        }

        img {
            object-fit: cover;
            max-height: 400px;
            width: 100% !important;
            max-width: 100% !important;
            margin: 2rem 0;
        }

        .mockup-img {
            width: 100% !important;
            height: 400px;
        }

        .services-step-container {
            .col {
                margin: 0 0 1.5rem;
                padding-bottom: 0;
                padding-left: 0.75rem;
                padding-right: 0.75rem;

                .col-inner {
                    padding: 1rem 1rem 2rem;
                    background-color: white;
                    height: 100%;

                    h3 {
                        font-size: 1.25rem !important;
                        font-family: $font-family-text;
                        margin-top: 2rem;
                    }

                    p {
                        margin-top: 0 !important;
                        max-width: 95%;
                        line-height: 1.65rem;
                    }
                }
            }
        }

        .services-section-header {
            margin-top: 5rem;
            margin-bottom: 2rem;

            .services-section-banner {
                min-height: 350px;
                background-position: center center;
                background-size: cover;
                margin: 0;
                background-position-y: 80%;

                // center title
                display: flex;
                justify-content: center;
                align-items: center;

                h2, p {
                    color: $white;
                    text-shadow: 0 0 8px #222222;
                }

                h2 {
                    font-size: 3rem !important;
                    font-family: $font-family-text;
                    font-weight: 700;
                    text-align: center !important;
                    max-width: inherit !important;

                    & + p {
                        max-width: inherit !important;
                        font-size: 1.75rem !important;
                        padding-bottom: 0 !important;
                    }
                }
            }
        }
        // targets last .services-section-header
        div:nth-child(11) > div > div {
            background-position-y: top !important;
        }
    }

    &[data-slug="sustainability-certifications"] {
        .page_presentation {
            background-image: url("https://cdn.tag-walk.com/showroom/linendreamlab/homepage/section-sustainability-certifications.jpg");
        }

        & > p:first-of-type {
            font-size: 1.25rem !important;
            padding-top: 1rem !important;
            padding-bottom: 3rem !important;
        }

        hr {
            margin-top: 2rem;
            margin-bottom: 4rem;
        }

        .sustainability-certifications-section-container {
            display: flex;
            align-items: center;
            background-color: white;
            padding: 3rem;
            margin-bottom: 5rem;

            div:nth-of-type(1) {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            h2 {
                font-family: $font-family-text;
                text-align: left !important;
                max-width: inherit !important;
                font-size: 1.75rem !important;

                a {
                    text-decoration: none;
                }
            }

            img {
                object-fit: contain;
                max-height: 320px;
                max-width: 100% !important;
                margin: 2rem 0 2rem auto;
            }

            p {
                text-align: justify;
            }
        }

        .sustainability-certifications-step-container {
            .col {
                margin: 0 0 1.5rem;
                padding-bottom: 0;
                padding-left: 0.75rem;
                padding-right: 0.75rem;

                .col-inner {
                    padding: 1rem 1rem 2rem;
                    background-color: white;
                    height: 100%;

                    h3 {
                        font-size: 1.25rem !important;
                        font-family: $font-family-text;
                        margin-top: 2rem;
                    }

                    p {
                        margin-top: 0 !important;
                        max-width: 95%;
                        text-align: justify;
                        line-height: 1.65rem;
                    }
                }
            }

            & + p {
                margin-top: 2rem;
                font-style: italic;
            }
        }
    }

    &[data-slug="european-industry"] {
        .page_presentation {
            background-image: url("https://cdn.tag-walk.com/showroom/linendreamlab/homepage/section-european-industry.jpg");
        }

        h2 {
            font-size: 1.5rem !important;
            font-family: $font-family-text;
            padding-bottom: 2rem !important;
        }

        p {
            font-size: 1.25rem !important;
        }

        .european-industry-section-container {
            display: flex;
            align-items: center;
            background-color: white;
            padding: 3rem;

            img {
                object-fit: contain;
                max-height: 300px;
                margin: 2rem 0 2rem auto;
            }

            p {
                text-align: center;
            }
        }

        &> h2:last-of-type {
            font-family: $font-family-text;
            font-size: 1.5rem !important;

            &+ p {
                font-size: 1.25rem !important;
                padding-bottom: 0;

                &+ ul {
                    font-size: 1.25rem !important;
                    margin-bottom: 3rem;
                }
            }
        }

        .european-industry-step-container {
            &:nth-of-type(3) .col:nth-of-type(2) .col-inner {
                background-position-x: right !important;
            }

            .col {
                margin-bottom: 1.5rem;
                padding-bottom: 0;
                padding-left: 0.75rem;
                padding-right: 0.75rem;

                .col-inner {
                    padding: 0;
                    background-color: white;
                    height: 600px;

                    .european-industry-step-text {
                        position: absolute;
                        left: 10%;
                        bottom: 3rem;
                        background-color: white;
                        width: 80%;
                        margin: auto;
                        padding-top: 1rem;
                    }

                    h3 {
                        font-size: 1.5rem !important;
                        font-family: $font-family-text;
                        padding-bottom: 0 !important;
                        text-transform: uppercase;
                    }

                    p {
                        font-size: 1.15rem !important;
                        margin-top: 0 !important;
                        max-width: 70%;
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        max-width: 100%;
                        height: 550px;
                        min-height: 480px;
                    }
                }
            }
        }
    }

    &[data-slug="trends-and-sourcing"] {
        h2 {
            font-size: 1.5rem !important;
            font-family: $font-family-text;
            padding-bottom: 2rem !important;
        }

        p {
            font-size: 1.25rem !important;
        }

        i {
            font-size: 1rem !important;
        }

        h3 {
            margin: 0 !important;
        }

        hr {
            margin-top: 2rem;
            margin-bottom: 4rem;
        }

        .trends-and-sourcing-section-container {
            text-decoration: none;

            /**
             * When each trend is displayed on one column
             * then the image block must appears before
             * the text block
             */
            @include media-breakpoint-down(lg) {
                .col-xl-7 {
                    order: 1 !important;
                }

                .col-xl-5 {
                    order: 2 !important;
                }
            }

            @include media-breakpoint-down(md) {
                .col-xl-7 {
                    padding: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        margin: 0 0 2rem;
                    }
                }
            }

            display: flex;
            align-items: stretch;
            padding: 3rem;
            margin-bottom: 5rem;

            p {
                font-size: 1rem !important;
                text-align: center;
            }

            .col-12:first-of-type {
                height: 100%;
            }

            .trend-section {
                background-color: white;
                padding: 2rem;

                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    text-align: justify !important;
                }
            }

            img {
                margin-bottom: 2rem;
                width: 100% !important;
            }
            .see-section {
                width: 100%;
                margin-top: 2rem;
                text-transform: uppercase;
            }
        }
    }

    &[data-slug="performance"] {
        & > p:first-of-type {
            font-size: 1.25rem !important;
            padding-top: 1rem !important;
            padding-bottom: 3rem !important;
        }

        .performance-section-container {
            display: flex;
            align-items: center;
            background-color: white;
            padding: 1.5rem 3rem;
            margin-bottom: 5rem;

            .col:nth-of-type(2) {
                padding-left: 2rem !important;
                padding-right: 2rem !important;
            }

            h2 {
                font-family: $font-family-text;
                font-size: 1.75rem !important;
            }

            img {
                object-fit: cover;
                margin: 2rem auto;
                height: 600px;
            }

            p {
                text-align: center;
                padding-bottom: 2rem !important;
            }

            ul {
                padding-left: 0;
                padding-bottom: 1.5rem;
                width: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                max-width: none;

                li {
                    width: 50%;
                    position: relative;
                    z-index: 10;
                    margin: 0;
                    line-height: 1.5rem;
                    padding-bottom: 2.5rem;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 6rem;
                        width: 6rem;
                        background-color: var(--primary-color);
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -10%);
                        border-radius: 50%;
                        z-index: -1;
                    }

                    &::first-line {
                        font-family: $font-family-bold;
                        line-height: 5rem;
                        margin: 2rem;
                    }
                }
            }
        }

        .performance-step-container {
            .col {
                margin: 0 0 1.5rem;
                padding-bottom: 0;
                padding-left: 0.75rem;
                padding-right: 0.75rem;

                .col-inner {
                    padding: 1rem 1rem 2rem;
                    background-color: white;
                    height: 100%;

                    h3 {
                        font-size: 1.25rem !important;
                        font-family: $font-family-text;
                        margin-top: 2rem;
                    }

                    p {
                        margin-top: 0 !important;
                        max-width: 95%;
                        text-align: justify;
                        line-height: 1.65rem;
                    }
                }
            }

            &:nth-of-type(3) p {
                text-align: center !important;
            }
        }
    }

    &[data-slug="legal-notice-data-privacy"] {
        table {
            display: table;
            margin: 0 auto 3rem auto;
            border: 1px solid $lightgray;
            border-collapse: collapse;

            th, td {
                border: 1px solid $lightgray;
                padding: 0.5rem 1rem;
            }
        }
    }

    .page-services .col {
        h3 {
            max-width: 90%;
        }

        p {
            max-width: 80%;
        }
    }
}

.trends {
    padding: 3rem;
    margin-top: 1rem;
    margin-bottom: 3rem;

    &[data-trend="curated"] {
        background-color: wheat;
    }

    &[data-trend="essential"] {
        background-color: gainsboro;
    }

    &[data-trend="reconnected"] {
        background-color: cadetblue;
    }

    &[data-trend="engaging"] {
        background-color: peachpuff;
    }

    &[data-trend="improving"] {
        background-color: mistyrose;
    }

    &[data-trend="growing"] {
        background-color: khaki;
    }

    &[data-trend="unique"] {
        background-color: lightpink;
    }

    &[data-trend="glamorous"] {
        background-color: lavender;
    }

    .trends-presentation {
        display: flex;
        padding-left: 3rem;
        padding-right: 3rem;

        .trends-description {
            font-family: $font-family-base;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            text-align: left;
            background-color: white;
            padding: 2rem;

            & .row {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        p {
            font-size: 1rem !important;
            padding-bottom: 1rem;
            text-align: justify;
        }

        h1 {
            font-family: $font-family-text;
            font-size: 1.25rem !important;
        }

        justify-content: center;
        align-items: center;

        .trends-image img {
            width: 100%;

            @include media-breakpoint-down(lg) {
                padding-bottom: 3rem;
            }
        }
    }
}


.filters-container .filters {
    @include media-breakpoint-up(lg) {
        .filter {
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
    }
    .filter-composition,
    .filter-certification,
    .filter-sector,
    .filter-quantity {
        @extend .col;
    }
}

.page-title .page-title-content .description {
    font-family: $font-family-text;
    font-size: 1.5rem;
    & > * {
        color: $dark !important;
    }
    strong {
        font-size: 2rem;
    }
}

.popup-modal {
    .close {
        margin: -1rem -1rem -1rem;
    }
    .modal-title {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-bold;
        font-size: 1.25rem;
        width: 100%;
        margin-right: -2rem;
    }
    .modal-header,
    .modal-footer {
        border: none;
    }
    .modal-body p {
        margin: 0 1rem;
        text-align: center;
        font-family: $font-family-text;
    }
    .modal-footer a {
        width: 100%;
        margin: 1rem;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.modal-backdrop.show {
    opacity: .3;
}

.list-user {
    thead th:nth-child(5),
    .user-jobtitle {
        display: none;
    }
}

body .photo-list .photo-list-item,
body .viewer .modal-container .viewer-content .viewer-body .carousel {
    .carousel-item:hover .carousel-item-badge,
    &:hover .photo-badge {
        opacity: 1;
        transition: .2s;
    }

    .photo-badges,
    .carousel-item-badges {
        opacity: .75;
        top: unset;
        left: 50%;
        bottom: 15px;
        width: calc(100% - 30px);
        transform: translate(-50%, 0%);
        transition: .2s;
        font-size: .7rem;
        .photo-badge,
        .carousel-item-badge {
            width: 100%;
            color: $black;
            border-radius: 0;
            background-color: $white;
            height: unset;
        }
    }

    .carousel-item .carousel-item-badge {
        font-size: .9rem;
        padding: .75rem;
        bottom: 7.5%;
    }
}

.image[data-has-embed] .carousel-item::before,
.img-container[data-has-embed]::before {
    display: none;
}

.viewer .carousel-item-badge {
    display: none;
    @include badge;
    top: 20px;
}

.overview-container {
    td {
        background-color: $white;
    }
}

#search-tools #count-results {
    display: none;
}
