@import '~bootstrap/scss/mixins';

$white: #ffffff;
$black-badge: rgba(0, 0, 0, .27);
$gainsboro: #ededed;

@mixin badge {
    @extend .text-uppercase;
    @extend .text-center;
    @extend .p-2;
    margin-bottom: .5rem;
    color: $white;
    border: 1px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black-badge;
    border-radius: 50%;
    font-size: .5rem;
    width: 65px;
    height: 65px;
}

@mixin badges {
    color: $white;
    position: absolute;
    left: 20px;
    top: 20px;
}

@mixin scrollbar {
    ::-webkit-scrollbar {
        width: 20px;
        border-width: 10px;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: $gainsboro;
        border-color: $white;
        border-width: 2px 9px 2px 9px;
        border-style: solid;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: $gainsboro;
        border-color: $white;
        border-style: solid;
        border-width: 1px 7px 1px 7px;
    }
}

@mixin horizontal-scrollbar($backgroundColor, $scrollbarColor) {
    ::-webkit-scrollbar {
        height: 20px;
        border-width: 10px;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: $scrollbarColor;
        border-color: $backgroundColor;
        border-width: 9px 2px 9px 2px;
        border-style: solid;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        border-radius: 7px;
        background-color: $scrollbarColor;
        border-color : $backgroundColor;
        border-style: solid;
        border-width: 7px 1px 7px 1px;
    }
}
